import React, {Component} from 'react'
import Background from 'shared/background';
import Button from 'components/button';
import Icon from 'components/icon';
import Link from 'shared/link';
import parse from 'html-react-parser';
import style from './searchCard.module.scss';

class SearchCard extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		const { name, photo, specialties, address, floor, city, state, zip, uri } = this.props;
		return (
			<Link to={uri}>
				<div className={style.card}>
					<div className={style.photoFrame}>
						<div className={style.image}>
							<Background className={style.photo} remoteImage={(photo) ? false : true} image={(photo) ? photo : `https://maps.googleapis.com/maps/api/staticmap?markers=${address.replace(/ /g,"+")}+${city.replace(/ /g,"+")},${state}+${zip}&zoom=16&size=300x400&key=${process.env.GOOGLE_MAPS_API_KEY}`}>
								{photo && <div className={style.map}>
									{/*<Background className={style.mapFPO} image="/userfiles/map-fpo.png" />*/}
									<Background className={style.mapFPO} remoteImage={true} image={`https://maps.googleapis.com/maps/api/staticmap?markers=size:small|${address.replace(/ /g,"+")}+${city.replace(/ /g,"+")},${state}+${zip}&zoom=15&size=100x100&key=${process.env.GOOGLE_MAPS_API_KEY}`} />
								</div>}
							</Background>
						</div>
					</div>
					<div className={style.content}>
						<div className={style.locationInfo}>
							<div className={style.name}>{name}</div>
							<p className={style.locAddress}>{address}<br />{floor ? parse(`${floor}<br />`) : ''}{city}, {state} {zip}</p>
						</div>
						{specialties && <div className={style.specialties}>
							<p className={style.specialtiesTitle}>Specialties</p>
							<div className={style.specialtiesList}>
								{specialties.map((specialty, index) => (
									<p key={index} className={style.specialty}>{specialty}</p>
								))}
							</div>
						</div>}
					</div>
				</div>
			</Link>
		)
	}
}

export default SearchCard;