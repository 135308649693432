import React, {Component, Fragment} from 'react'
import SearchCard from './searchCard'
import style from './results.module.scss';

class Results extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const data = this.props.data;
		return (
			<Fragment>
				<div className="grid-x">
					{(() => {
						switch (true) {
							case data.length > 0:   
								return (
									data.map((card, index) => (
										<div key={index} className={`${style.cardHolder} cell`}>
											<SearchCard {...card} />
										</div>
									))
								)
							case data.length <= 0: 
								return (
									<div className="cell small-12 text-center">
										<h4>Your search yielded no results</h4>
									</div>
								)
							default: return "No data";
						}
					})()}
				</div>
			</Fragment>
		)
	}
}

export default Results;