var haversine = module.exports = {};

// configurable earthRadius (in meters)
haversine.earthRadius = 6371000;

// convert degrees to radians
haversine.degToRad = function(deg) {
  return deg * (Math.PI / 180);
};

// distance between two points
haversine.distance = function(coords1, coords2) {
	var lat1 = coords1[0];
	var lon1 = coords1[1];
	var lat2 = coords2[0];
	var lon2 = coords2[1];

	var latitudeDifference = this.degToRad(lat2 - lat1);
	var logitudeDifference = this.degToRad(lon2 - lon1);

	var n =
		Math.sin(latitudeDifference / 2) * Math.sin(latitudeDifference / 2) +
		Math.cos(this.degToRad(lat1)) * Math.cos(this.degToRad(lat2)) *
		Math.sin(logitudeDifference / 2) * Math.sin(logitudeDifference / 2);

	var distance = 2 * Math.atan2(Math.sqrt(n), Math.sqrt(1 - n));

	return this.earthRadius * distance;
};