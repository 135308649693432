import React, {Component} from 'react'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import haversine from 'helpers/haversine'
import Icon from 'components/icon'
import Pagination from 'components/pagination/'
import parse from 'html-react-parser'
import Results from './results'
import Select from 'components/select/'
import style from './search.module.scss';

const GOOGLE_GEOCODE_MAPS_API_KEY = 'AIzaSyC_EFO6zQqh6TWLM2REJaHj0KhkfQkbss8';

class LocationSearch extends Component {
	constructor(props) {
		super(props);
		this.resultsRef = React.createRef();
		this.specialties = [];
		this.state = {
			locations: this.props.data,
			allLocations: this.props.data,
			currentLocations: [],
			currentPage: null,
			totalPages: null,
			offset: 0,
			resultsScroll: null,
			checkboxes: this.specialties.reduce(
				(specialties, specialty) => ({
					...specialties,
					[specialty]: false
				}),
				{}
			),
			radius: '',
			place: null,
			placeValue: {},
			filterToggle: true,
			searchQuery: null,
			resultQuery: null,
			showValue: true,
		};
		this.searchQuery = {};
		this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
		this.clearFilters = this.clearFilters.bind(this);
		this.radiusChange = this.radiusChange.bind(this);
		this.placeChange = this.placeChange.bind(this);
		this.clearSearch = this.clearSearch.bind(this);
		this.filterToggle = this.filterToggle.bind(this);
		this.onPageChanged = this.onPageChanged.bind(this);
		this.filterLocations = this.filterLocations.bind(this);
		this.removeDuplicateObjectFromArray = this.removeDuplicateObjectFromArray.bind(this);
	}

	onPageChanged = data => {
		const { allLocations } = this.state;
		const { currentPage, totalPages, pageLimit } = data;

		const offset = (currentPage - 1) * pageLimit;
		const currentLocations = allLocations.slice(offset, offset + pageLimit);
		this.setState({ currentPage, currentLocations, totalPages, offset });
		if (this.state.resultsScroll === null) {
			this.setState({
				resultsScroll: true,
			})
		} else if (this.state.resultsScroll === true) {
			let scrollOffset = this.resultsRef.current.offsetTop-60;
			window.scroll({top: scrollOffset, left: 0, behavior: 'smooth' });
		}
	}

	filterToggle() {
		this.setState(prevState => ({
			filterToggle: !prevState.filterToggle,
		}));
	}

	handleCheckboxChange(event) {
		event.preventDefault();
		event.persist();
		let name = event.currentTarget.dataset.name;
		this.setState(prevState => ({
			checkboxes: {
				...prevState.checkboxes,
				[name]: !prevState.checkboxes[name]
			}
		}));
	};

	filterLocations() {
		let filteredLocations = [];
		let boxes = this.state.checkboxes;
		let emptyBoxes = true;
		for (const box in boxes) {
			if (boxes[box]) {
				emptyBoxes = false;
				this.state.allLocationData.forEach((location) => {
					if (location.specialties !== null) {
						if (location.specialties.length && location.specialties.indexOf(box) !== -1) {
							filteredLocations.push(location);
						}
					}
				})
			}
		}
		if (!emptyBoxes) {
			filteredLocations = this.removeDuplicateObjectFromArray(filteredLocations, 'name');
			filteredLocations.sort((a, b) => {
				let fa = a.name.toLowerCase(),
					fb = b.name.toLowerCase();

				if (fa < fb) {
					return -1;
				}
				if (fa > fb) {
					return 1;
				}
				return 0;
			});
		} else {
			filteredLocations = this.state.allLocationData;
		}
		this.setState({
			allLocations: filteredLocations,
			page: 1,
		});
	}

	componentDidUpdate(prevProps, prevState) {
		let checkFilters = false;
		let runFilter = false;
		for (const box in this.state.checkboxes) {
			if (this.state.checkboxes[box] !== prevState.checkboxes[box]) {
				checkFilters = true;
			}
		}
		if (checkFilters) {
			for (const box in this.state.checkboxes) {
				if (this.state.checkboxes[box]) {
					runFilter = true;
				}
			}
			if (runFilter) {
				this.filterLocations();
			} else {
				this.setState({
					allLocations: this.state.allLocationData,
					page: 1,
				});
			}
		}
	}

	clearFilters(event) {
		for (const box in this.state.checkboxes) {
			this.setState({
				checkboxes: {
					[box]: false,
				}
			});
		}
		this.setState({
			allLocations: this.state.allLocationData,
			page: 1,
		})
	}

	radiusChange(event) {
		this.setState({radius: parseInt(event.target.value)});
	}

	placeChange(event) {
		let query = {
			radius: this.state.radius,
			place: this.state.place,
		}
		if (query.radius === '') {
			query.radius = 25;
		}
		this.setState({
			placeValue: event,
			showValue: true,
		})
		fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(event.value.description)}&key=${GOOGLE_GEOCODE_MAPS_API_KEY}`)
		.then(response => response.json())
		.then(({results}) => {
			query.place = results[0].geometry.location;
			let place = results[0].geometry.location;
			let filteredLocations = [];
			this.state.allLocationData.forEach((location) => {
				let distance = haversine.distance([ place['lat'], place['lng'] ], [ location['latitude'], location['longitude'] ]);
				distance = (distance * 0.0006213712);
				if (distance <= query.radius) {
					// filter out offices based off of distance from the zip code entered
					filteredLocations.push({
						...location,
						distance: distance,
					});
				}
			});
			filteredLocations.sort((left, right) => (left.distance - right.distance));
			this.setState({
				place: results[0].geometry.location,
				allLocations: filteredLocations,
				page: 1,
			});
			const pageLimit = 4;
			const currentPage = 1;
			const offset = (currentPage - 1) * pageLimit;
			const currentLocations = filteredLocations.slice(offset, offset + pageLimit);
			this.setState({ currentPage, currentLocations, offset});
			if (this.state.resultsScroll === null) {
				this.setState({
					resultsScroll: true,
				})
			} else if (this.state.resultsScroll === true) {
				let scrollOffset = this.resultsRef.current.offsetTop-60;
				window.scroll({top: scrollOffset, left: 0, behavior: 'smooth' });
			}
		})
		.catch(err => console.log);
		console.log(query);
	}

	clearSearch(event) {
		this.setState({
			radius: '',
			place: null,
			placeValue: null,
			allLocations: this.state.allLocationData,
			page: null,
			showValue: false,
		});
		const pageLimit = 4;
		const currentPage = 1;
		const offset = (currentPage - 1) * pageLimit;
		const currentLocations = this.state.allLocationData.slice(offset, offset + pageLimit);
		this.setState({ currentPage, currentLocations, offset});
		if (this.state.resultsScroll === null) {
			this.setState({
				resultsScroll: true,
			})
		} else if (this.state.resultsScroll === true) {
			let scrollOffset = this.resultsRef.current.offsetTop-60;
			window.scroll({top: scrollOffset, left: 0, behavior: 'smooth' });
		}
	}

	removeDuplicateObjectFromArray(array, key) {
		return array.filter((obj, index, self) =>
			index === self.findIndex((el) => (
				el[key] === obj[key]
			))
		)
	}

	componentDidMount() {
		const allLocations = this.props.data;

		// Get available specialties from locations
		let allSpecialties = [];
		allLocations.forEach(location => {
			if (location.specialties) {
				location.specialties.forEach(item => {
					allSpecialties.push(item);
				});
			}
		});
		allSpecialties = [...new Set(allSpecialties)];
		allSpecialties.sort();
		this.specialties = allSpecialties;

		// Setting state of providers and locations options
		this.setState({ 
			allLocations: allLocations, 
			allLocationData: allLocations,
			checkboxes: this.specialties.reduce(
				(specialties, specialty) => ({
					...specialties,
					[specialty]: false
				}),
				{}
			),
		});
	}

	render() {
		var { allLocations, currentLocations, currentPage, totalPages } = this.state;
		var totalLocations = this.state.allLocations.length;
		// if (totalLocations === 0) return null;
		console.log(allLocations);
		return (
			<div className={[style.container, "grid-container"].join(' ')}>
				<div className="grid-x">
					<div className={[style.filtersWrapper, "cell medium-4"].join(' ')}>
						<div className={[style.filtersHeader, "grid-x"].join(' ')}>
							<div className="cell shrink hide-for-medium">
								<button onClick={this.filterToggle} className={style.filterToggle}>
									<Icon icon={this.state.filterToggle ? 'downChevron' : 'upChevron'} />
								</button>
							</div>
							<div onClick={this.filterToggle} className="cell auto"><h3>Filter Results</h3></div>
							<div className="cell shrink"><div onClick={this.clearFilters} className={style.clear}>Clear Filter</div></div>
						</div>
						<div className={[style.searchFilters, (this.state.filterToggle ? style.collapsed : style.expanded)].join(' ')}>
							<div className={[style.specialtyFilters, "grid-x"].join(' ')}>
								<div className={[style.filterTitle, "cell small-12"].join(' ')}>Specialty</div>
								{this.specialties.map((specialty, index) => (
									<div key={index} data-name={specialty} onClick={this.handleCheckboxChange} className={[style.specialty, (this.state.checkboxes[specialty]) ? style.selected : '', "cell small-12"].join(' ')}><div className={style.specialtyBox}></div><div className={style.specialtyLabel}>{specialty}</div></div>
								))}
							</div>
							<div className={[style.distanceFiltersRow, "grid-x"].join(' ')}>
								<div className={[style.filterTitle, "cell small-12"].join(' ')}>Location / Distance</div>
								<div className={[style.distanceFilters, "cell"].join(' ')}>
									<span style={{display:'flex', flexShrink: 1, flexDirection: 'row', alignItems: 'center'}}>Within <input type="number" onChange={this.radiusChange} value={this.state.radius} placeholder="miles" name="radius" /> of </span>
									<GooglePlacesAutocomplete
										apiKey={GOOGLE_GEOCODE_MAPS_API_KEY}
										selectProps={{
											styles: {
												container: (provided) => ({
													...provided,
													marginLeft: '0.4375rem',
													flexGrow: 1,
												}),
												control: (provided) => ({
													...provided,
													borderRadius: 0,
													border: '1px solid #cacaca',
													boxShadow: 'inset 0 1px 2px rgb(0 0 0 / 10%)',
												}),
												input: (provided) => ({
													...provided,
													marginBottom: 0,
													height: '31px',
													fontSize: '15px',
													padding: 0,
													boxShadow: 'none',
													margin: 0,
												}),
												placeholder: (provided) => ({
													...provided,
													color: '#CACACA',
													fontSize: '15px',
												}),
												singleValue: (provided) => ({
													...provided,
													top: '60%',
													boxShadow: 'none',
												}),
												valueContainer: (provided) => ({
													...provided,
													boxShadow: 'none',
												})
											},
											placeholder: 'enter address, city or zip',
											onChange: this.placeChange,
											singleValue: this.state.placeValue,
											controlShouldRenderValue: this.state.showValue,
										}}
									/>
								</div>
								<div className={style.clearSearch}><span onClick={this.clearSearch}>Clear Search</span></div>
							</div>
						</div>
					</div>
					<div className={[style.resultsWrapper, "cell medium-8"].join(' ')}>
						<div ref={this.resultsRef} className={[style.resultsNumber, "grid-x"].join(' ')}>
							<div className={"cell text-right"}><span className={totalLocations > 0 ? '' : 'hide'}>{this.state.offset+1} - {(this.state.offset+4 > totalLocations) ? totalLocations : this.state.offset+4} / {(totalLocations > 0) ? `${totalLocations} results` : ''}</span>{totalLocations > 0 ? '' : parse('&nbsp;')}</div>
						</div>
						<Results data={currentLocations} />
						<Pagination totalRecords={totalLocations} currentPage={currentPage} pageLimit={4} pageNeighbors={1} onPageChanged={this.onPageChanged} />
					</div>
				</div>
			</div>
		)
	}
}

export default LocationSearch;