import React, { Component } from "react"
import { graphql } from 'gatsby'
// import $ from 'jquery';
// import { Router } from "@reach/router"
import Layout from 'components/layout/basic';
import MastheadInterior from 'components/masthead/interior'
import LocationsSearchController from 'controllers/locations/search';
import SEO from 'shared/seo';

export default class LocationsPage extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<>
				<Layout>
					<MastheadInterior title="Location Search" intro="Search for a location near you or filter by specialty." />
					<hr />
					<LocationsSearchController />
					<SEO />
				</Layout>
			</>
		)
	}
}