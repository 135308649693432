import React, {Component} from 'react'
import { StaticQuery, graphql } from 'gatsby'
// Import neccessary site components, try to maintain alphabetical order
import LocationSearch from 'components/locations/search';

const STATUS = {
	INITIAL: 1,
	LOADING: 2,
	LOADED: 3,
	FAIL: 4,
}


class LocationsSearchController extends Component {

	liveRefresh() {
		fetch(`${process.env.GATSBY_ZMS_API_URL}locations/location/all/?_join=true`)
			.then(response => response.json())
			.then(({data}) => {
				if (data.length) {
					let locations = [];
					data.forEach(location => {
						locations.push(this.processData(location));
					});
					this.setState({
						status: STATUS.LOADED,
						data: this.shuffleArray(locations),
					});
					console.log('Live Refresh Complete');
				}
			})
			.catch(err => console.log);
	}

	processData(data) {
		let newDatum = {
			name: data.name,
			address: data.address1,
			floor: data.address2,
			city: data.city,
			state: data.state,
			zip: data.zip,
			photo: data.photo,
			uri: data.uri,
			latitude: data.position[0],
			longitude: data.position[1],
			specialties: null,
			sort: data.sort ? data.sort : 1,
		}
		if (data.Specialty && data.Specialty.length) {
			let specialties = [];
			data.Specialty.forEach(specialty => {
				specialties.push(specialty.object.name);
			});
			if (specialties.length) {
				specialties.sort();
				newDatum.specialties = specialties;
			} else {
				newDatum.specialties = null;
			}
		}
		return newDatum;
	}

	constructor(props) {
		super(props);

		let initialData = [];
		if (props.data && props.data.allLocationsLocation && props.data.allLocationsLocation.edges.length) {
			props.data.allLocationsLocation.edges.forEach(location => {
				initialData.push(this.processData(location.node));
			});
		}

		this.state = {
			status: STATUS.INITIAL,
			data: this.shuffleArray(initialData),
		}
		this.liveRefresh = this.liveRefresh.bind(this);
		this.shuffleArray = this.shuffleArray.bind(this);
		this.processData = this.processData.bind(this);
	}

	shuffleArray(array) {
		// array.sort((left, right) => (left.randSort - right.randSort));
		array.sort((a, b) => {
			let fa = a.sort,
				fb = b.sort;

			if (fa < fb) {
				return -1;
			}
			if (fa > fb) {
				return 1;
			}
			return 0;
		});
		return array;
	}

	componentDidMount() {
		this.liveRefresh();
	}

	render() {
		return (
			<LocationSearch data={this.state.data} />
		);
	}
}

export default (props) => (
	<StaticQuery
		query={graphql`
			{
			  allLocationsLocation(filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
			    edges {
			      node {
			      	name
			        address1
			        address2
			        city
			        state
			        zip
			        photo
			        uri
			        position
			        sort
			        Specialty {
			          object {
			            name
			          }
			        }
			      }
			    }
			  }
			}
		`}
		render={data => (<LocationsSearchController data={data} {...props} />)}
	/>
)